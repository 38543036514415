.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.container {
    // max-width: 1440px;
    // background-color:rgb(233,234,236);
    min-height:100vh;
    margin: 0 auto;
    width: 70%;
    // position: relative;
    // display: grid;
    // height: 100vh;
    // grid-template-rows: 1fr;
    // grid-auto-rows: calc(var(--menu-height) + env(safe-area-inset-bottom));

    @media(max-width:768px) {
        width: 100%;
        padding: 0rem 1rem ;
    }
  }

@mixin top {
    @media(max-width:1200px) {
        @content;
    }
}

@mixin pad {
    @media(max-width:1024px) {
        @content;
    }
}

@mixin mobile {
    @media(max-width:720px) {
        @content;
    }
}

@mixin smobile {
    @media(max-width:375px) {
        @content;
    }
}