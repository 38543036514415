*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  /* 手機觸碰按鈕不會有怪怪顏色跳出來 */
  -webkit-tap-highlight-color: transparent;
  /* overflow: hidden; */
}

body h1 {
  font-size: 32px;
  font-weight: 500;
  color: #404040;
}

body h2 {
  font-size: 24px;
  /* font-weight: 500; */
}

body h3 {
  font-size: 20px;
  font-weight: 700;
  color: #404040;
}

body h4 {
  font-size: 18px;
  font-weight: 500;
}

body h5 {
  font-size: 16px;
  font-weight: 500;
  color: #404040;
}

body p {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #404040;
  font-weight: 500;
}