.buttonWrap {
    width: 100%;

    >button {
        width: 100%;
        border: unset;
        border-radius: 8px;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .pic {
            background-repeat: no-repeat;
            width: 15px;
            height: 15px;
            background-position: center;
        }
    }

    >button:disabled {
        background: #c0c0c0;
    }

    .grey {
        background-color: #C0C0C0;
    }

    .green {
        background-color: #5FC17F;
    }

    .yellow {
        background: linear-gradient(180deg, #FFCF21 0%, #F4AE44 100%);
    }

    .red {
        background-color: #C83A20;
    }

    .none {
        background: #fff;
        border: 1px solid #E0E0E0;
        color: #E0E0E0;
    }

    .none_grey {
        background: #fff;
        border: 1px solid #D6D5E4;
        color: #C0C0C0;
    }
}