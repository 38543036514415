
.inputWrap {
    position: relative;

    .searchPicWrap {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .eyes {
        position: absolute;
        right: 12px;
        height: 36px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }


    >input {
        background-color: #fff;
        appearance: none;
        border: unset;
        border: 1px solid #E0E0E0;
        border-radius: 5px;
        width: 100%;
        height: 36px;
        outline: unset;
        transition: 0.2s all ease-in-out;
        color: #404040;
        font-size: 14px;
        letter-spacing: 0.5px;
        box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;

        &:-webkit-autofill {
            // -webkit-background-color: #fff;
            -webkit-box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
            -webkit-text-fill-color: #333;
        }

        &::placeholder {
            color: #A0A0A0;
            padding: 0 0 0 0px;
        }

        &:focus {
            border: 1px solid #5FC17F;
        }

        &:disabled {
            background-color: #ebebeb;
        }
    }

}