@import '../../app.module.scss';

.homeWrap{
    // width: 70%;
    width: 100%;
    
    .loadingWrap{
        width: 100%;
        height: 100vh;
        position: fixed;
        left: 0;
        right: 0;
        // top: 0;
        // bottom: 0;
        // margin: auto;

        // display: flex;
        // align-items: center;
        // justify-content: center;
        z-index: 999;
    }

    .header{
        width: 100%;
        padding: 3rem 0 2rem 0;
    }

    .content{
        width: 100%;
        background-color: #fff;
        padding: 2rem 3rem 50px 3rem;
        border-radius: 10px;
        // min-height:700px;
        min-height:calc(100vh - 150px);
        position: relative;

        @include mobile {
            padding: 2rem 2rem 50px 2rem;
        }

        .switchTitle{
            display: flex;
            align-items: center;
            gap:20px;
        }

        .titleContent{
            padding-top: 0.5rem;
            &>p{
                line-height: 20px;

                &>a{
                    color:#1f87e8;
                }
            }
        }

        .formWrap{
            padding: 1rem 0;

            .innerContent{
                display: flex;
                align-items:baseline;
                gap:5px;

                &>a{
                    font-size: 12px;
                    color:#1f87e8;
                }
            }

            .custom{
                display: flex;
                align-items: center;
            }

            .Blist{
                width: 100%;
                padding: 0 0 0rem 2.6rem;
                // max-height:300px;
                // background-color: #fda;

                .innerContent{
                    @include mobile {
                        flex-direction: column;
                    }
                }
                
                .enterWrap{
                    width: 100%;
                    display: flex;
                    padding-top: 0.5rem;;

                    .inpWrap{
                        width: 100%;
                    }

                    &>span{
                        display: block;
                        min-width: 36px;
                        background-size: 24px;
                        background-repeat: no-repeat;
                        background-position:6px center;
                        cursor: pointer;
                    }
                }

                @include mobile {
                    padding: 0 0 0rem 0rem;
                }

                .innerList{
                    width: 100%;
                    padding-top: 1rem;
                    display: flex;
                    flex-wrap:wrap;
                    gap:1rem 1rem;
                    // height: 300px;
                    // height:calc(50vh - 120px);
                    overflow-y: scroll;
                    max-height:calc(100vh - 500px);

                    @include mobile {
                        max-height:calc(100vh - 550px);
                    }

                    &>li{
                        width:calc(50% - 0.5rem);
                        display: flex;
                        height: 36px;
                        // background-color: #fda;

                        @include mobile {
                            width: 100%;
                        }

                        .inputWrap{
                            width:calc(100% - 36px);
                            display: flex;
                            align-items: center;
                            border-bottom: 1px solid #e0e0e0;
                            overflow-x: scroll;

                            &>p{
                                color:#B6B6B6;
                            }
                        }

                        &>span{
                            display: block;
                            min-width: 36px;
                            background-size: 24px;
                            background-repeat: no-repeat;
                            background-position:6px center;
                            cursor: pointer;
                            // background-color: #fda;
                        }
                    }
                }
            }

            .comfirmWrap{
                position: absolute;
                bottom: 20px;
                left: 0;
                width: 100%;
                height: 50px;
                // background-color: rgb(241, 241, 241);
                display: flex;
                justify-content:flex-end;
                padding-right: 3rem;

                @include mobile {
                    padding-left: 2rem;
                    padding-right: 2rem;
                    justify-content:center
                }

                .btnWrap{
                    width: 100px;

                    @include mobile {
                        width: 100%;
                    }
                }
            }
        }
    }
}